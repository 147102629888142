import Header from './header';
import Main from './main';

export default function Painel(){

  const painel = (
    <>
      <Header/>
      <Main/>
    </>
  );
  

  return painel;
  
}
